import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box } from '@primer/react';
import { InfoIcon, CheckCircleIcon, StopIcon, AlertIcon } from '@primer/octicons-react';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CustomVideoPlayer = makeShortcode("CustomVideoPlayer");
const Caption = makeShortcode("Caption");
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Primer messaging components:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../components/banner"
        }}>{`Banner`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../components/inline-message"
        }}>{`InlineMessage`}</a></li>
    </ul>
    <h2>{`Message types`}</h2>
    <p>{`Messaging holds differing levels of prominence which can be used to determine the appropriate component to use. Most messages fall into one of the following categories, from most prominent to least:`}</p>
    <p><strong parentName="p">{`System updates`}</strong>{`: These types of messages originate from GitHub and are not user initiated. System announcements related to a specific product area such as a single sign-on message should use the `}<strong parentName="p">{`Banner`}</strong>{` component. System update type messages cannot be dismissed until the issue is resolved, or may be permanent if they are purely informative. Global system announcements that are relevant beyond the current page use an `}<strong parentName="p">{`Announcement`}</strong>{` (not a Primer component).`}</p>
    <p><strong parentName="p">{`Feedback`}</strong>{`: Communicates the result of a user action (submitting a form, toggling a setting, etc.) Feedback can be positive, negative, or a warning. Feedback is shown in either a `}<strong parentName="p">{`Banner`}</strong>{` or `}<strong parentName="p">{`Inline message`}</strong>{`. For forms, an inline message is used for individual field validation which may be combined with a banner if multiple fields have errors. Banners are placed at the top of the page or section they are related to, but still within the body content. Inline messages are placed near the action they are related to.`}</p>
    <p><strong parentName="p">{`Awareness`}</strong>{`: Communicates information that is relevant to the user but not necessarily related to an action they've taken. This type of message is typically used to provide context or additional information about a feature or product. A `}<strong parentName="p">{`Banner`}</strong>{` using the `}<inlineCode parentName="p">{`info`}</inlineCode>{` state might be used to suggest an action, while an `}<strong parentName="p">{`InlineMessage`}</strong>{` might suggest a helpful tip.`}</p>
    <p>{`See the `}<a parentName="p" {...{
        "href": "#choosing-a-message-type"
      }}>{`message type flowchart`}</a>{` to help determine the appropriate message type for specific use cases.`}</p>
    <h2>{`Message states`}</h2>
    <img width="960" alt="A group of labels showing the proper icon and color combination for each message state" src="https://github.com/primer/design/assets/18661030/bbcbe8a0-4d7f-47f0-9f20-c6cee406115f" />
    <h3>{`Visual treatment`}</h3>
    <p>{`Primer offers six states for messaging components: info, warning, success, unavailable, critical, and upsell. Each state has a corresponding icon and color combination to help communicate the message's intent.`}</p>
    <p>{`Icons sized at 12px utilize the filled variant, while 16px and 24px utilize the outline variant paired with 14px text or larger.`}</p>
    <img width="960" alt="An example of an input field with a 12px error message next to two examples of 14px icon usage, which uses an outline icon." src="https://github.com/user-attachments/assets/2ee98e32-0a1c-4b40-8111-80cea6cf1177" />
    <h3>{`Overview`}</h3>
    <p>{`Messaging components combine state with message type to provide the appropriate level of prominence. To determine which component to use for your message, start by identifying the message state.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`State`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Use case`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Box sx={{
              color: 'accent.fg',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem'
            }} mdxType="Box"><InfoIcon mdxType="InfoIcon" /><span>{`Info`}</span></Box></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Highlights important information that has an influence on the current view or offers an action.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Box sx={{
              color: 'attention.fg',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem'
            }} mdxType="Box"><StopIcon mdxType="StopIcon" /><span>{`Warning`}</span></Box></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Informs about a potential issue or consequence as the result of an action.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Box sx={{
              color: 'danger.fg',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem'
            }} mdxType="Box"><AlertIcon mdxType="AlertIcon" /><span>{`Critical`}</span></Box></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Informs about an error that occured or warns about loss of access or data as a result of an action.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Box sx={{
              color: 'success.fg',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem'
            }} mdxType="Box"><CheckCircleIcon mdxType="CheckCircleIcon" /><span>{`Success`}</span></Box></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Informs about successfully completing an action.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Box sx={{
              color: 'fg.muted',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem'
            }} mdxType="Box"><StopIcon mdxType="StopIcon" /><span>{`Unavailable`}</span></Box></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Informs about degraded experiences or outages.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Box sx={{
              color: 'done.fg',
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem'
            }} mdxType="Box"><InfoIcon mdxType="InfoIcon" /><span>{`Upsell`}</span></Box></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Highlights features that are available for specific account types such as Enterprise.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Info`}</h3>
    <p>{`Use the `}<inlineCode parentName="p">{`info`}</inlineCode>{` state to highlight messages that help users complete a task or that provide additional context.`}</p>
    <p>{`Examples:`}</p>
    <ul>
      <li parentName="ul">{`Single sign on or other account related notifications`}</li>
      <li parentName="ul">{`General information about a feature or setting`}</li>
    </ul>
    <img width="960" alt="A single sign on info banner on the GitHub homepage" src="https://github.com/primer/design/assets/18661030/1055049e-6db8-41d5-8cf8-e375bd0e8dea" />
    <h3>{`Warning`}</h3>
    <p>{`Use the `}<inlineCode parentName="p">{`warning`}</inlineCode>{` state to inform users of a potential issue, an important upcoming change that may impact their experience or a potentially unexpected consequence of an action.`}</p>
    <p>{`Examples:`}</p>
    <ul>
      <li parentName="ul">{`An account setting will become required soon`}</li>
      <li parentName="ul">{`A `}<a parentName="li" {...{
          "href": "./degraded-experiences"
        }}>{`degraded experience`}</a>{` due to connectivity or GitHub availability`}</li>
      <li parentName="ul">{`A non-destructive action with side-effects like costs or access changes is about to take place`}</li>
    </ul>
    <img width="960" alt="A yellow warning banner inside a repo Codespace settings page explaining that there may be a billable charge if settings are changed." src="https://github.com/user-attachments/assets/91e138d2-15ce-438b-b133-ac807afb3bf1" />
    <h3>{`Critical`}</h3>
    <p>{`Use the `}<inlineCode parentName="p">{`critical`}</inlineCode>{` state to notify users that an action was unsuccessful, to display form errors, or to inform that an action is destructive.`}</p>
    <p>{`Examples:`}</p>
    <ul>
      <li parentName="ul">{`Form field is invalid upon submitting`}</li>
      <li parentName="ul">{`A delete or transfer button`}</li>
    </ul>
    <img width="960" alt="A critical error message on an input field within GitHub Issues." src="https://github.com/primer/design/assets/18661030/5da2e479-6bc7-46dc-9179-46002412b9f5" />
    <h3>{`Success`}</h3>
    <p>{`Use the `}<inlineCode parentName="p">{`success`}</inlineCode>{` state to notify users that the results of an action were successful if it is not apparent from other parts of the UI. `}<strong parentName="p">{`Use success messaging sparingly`}</strong>{` and rely more on interaction context.`}</p>
    <p>{`Examples where a success message is needed:`}</p>
    <ul>
      <li parentName="ul">{`Copy button, the copy to clipboard action was successful.`}</li>
    </ul>
    <CustomVideoPlayer width="100%" src="https://github.com/user-attachments/assets/d9062f3f-66dd-4263-aae6-f6b8e748aba8" mdxType="CustomVideoPlayer" />
    <Caption mdxType="Caption">After clicking the copy icon button, a green checkmark appears to the right of the icon button.</Caption>
    <ul>
      <li parentName="ul">{`Creating multiple new issues.`}</li>
    </ul>
    <CustomVideoPlayer width="100%" src="https://github.com/user-attachments/assets/bd3bcbf6-e8ee-41e2-babf-942c56ba2ea6" mdxType="CustomVideoPlayer" />
    <Caption mdxType="Caption">After clicking the Create button, an inline message appears below the Create button that says, Issue #21 created, with Issue #21 being a link.</Caption>
    <p>{`Examples where a success message is `}<strong parentName="p">{`not`}</strong>{` needed:`}</p>
    <ul>
      <li parentName="ul">{`Saving the comment edit or title edit of an Issue, where the success is indicated by the return to the updated comment or title in non-edit mode.`}</li>
      <li parentName="ul">{`Creating a single new issue, where a user is brought to the newly created issue page upon successful creation.`}</li>
    </ul>
    <h3>{`Unavailable`}</h3>
    <p>{`Reserve the `}<inlineCode parentName="p">{`unavailable`}</inlineCode>{` state for `}<strong parentName="p">{`inline`}</strong>{` system related degraded experiences, or for surfacing account related permission messages.`}</p>
    <p>{`Examples:`}</p>
    <ul>
      <li parentName="ul">{`A row of a data table could not be loaded`}</li>
      <li parentName="ul">{`An item in a list could not be loaded`}</li>
      <li parentName="ul">{`User does not have permissions to perform an action`}</li>
    </ul>
    <p>{`See `}<a parentName="p" {...{
        "href": "./degraded-experiences"
      }}>{`degraded experiences`}</a>{` for more information.`}</p>
    <h3>{`Upsell`}</h3>
    <p>{`Use the `}<inlineCode parentName="p">{`upsell`}</inlineCode>{` state to highlight features that could be unlocked if the user upgrades their account.`}</p>
    <p>{`Examples:`}</p>
    <ul>
      <li parentName="ul">{`Upgrade account to access a feature`}</li>
    </ul>
    <img width="960" alt="An upsell banner inside the rulesets setting page on GitHub explaing that this feature can be used if the user upgrades to an Enterprise account." src="https://github.com/primer/design/assets/18661030/924884b8-4b85-4967-898d-e0c2fcfaf785" />
    <h2>{`Placement`}</h2>
    <p>{`Message proximity contributes to the prominence of a message. Whenever possible, place messages near the action they are related to.`}</p>
    <h3>{`Top of body`}</h3>
    <p>{`If messaging pertains to an entire page or section, place it at the top of the body content. This is the most common placement for messaging components. Banners should fill the container width but maintain appropriate spacing between elements and not appear full width or flush under the global nav.`}</p>
    <img width="960" alt="Highlights a banner at the top of the body inside a GitHub issue." src="https://github.com/primer/design/assets/18661030/73a2ace1-e4fe-4255-850d-062c8d75b7a9" />
    <h3>{`Inline`}</h3>
    <p>{`Inline messages are used to provide feedback on a specific action or input field. They should be placed near the action they are related to.`}</p>
    <img width="960" alt="Highlights an inline message next to an input field inside a GitHub issue." src="https://github.com/primer/design/assets/18661030/ea98bf69-1a7a-4061-8fce-2e2be4303b55" />
    <h3>{`Inside a dialog`}</h3>
    <p>{`Banners should be placed below the Dialog header and appear full width within the Dialog content area. Prioritize showing messaging `}<em parentName="p">{`inside`}</em>{` the Dialog after an action rather than closing the Dialog and showing a banner within the page.`}</p>
    <img width="960" alt="Highlights a banner inside a dialog showing an error state has occured." src="https://github.com/primer/design/assets/18661030/f4e3a125-94b6-4b12-b9ec-6b881c25f82c" />
    <h3>{`Placement recommendations`}</h3>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://github.com/user-attachments/assets/14cedc9f-44d2-4901-8d8c-3afa44d9d243" />
    <Caption mdxType="Caption">Place banners in close proximity to where the interaction occured.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://github.com/user-attachments/assets/0a2262cc-94aa-49b0-b547-c03047568aaa" />
    <Caption mdxType="Caption">Don't place banners related to internal dialog content at the top of a page.</Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://github.com/user-attachments/assets/3aae67be-e177-4d88-ae40-c00430388218" />
    <Caption mdxType="Caption">Use InlineMessage when immediate feedback is needed as the result of an action.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://github.com/user-attachments/assets/b52fcfaa-45e5-4e37-b17e-09033e950458" />
    <Caption mdxType="Caption">Don’t place messaging far from the user action when space is available.</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Choosing a message type`}</h2>
    <p>{`Picking a notification message for an experience can be tricky. Reference this flowchart to help pick the best component for your feature. For more nuanced scenarios, reach out to the Primer team for recommendations.`}</p>
    <img width="960" alt="" src="https://github.com/user-attachments/assets/b8635f19-629c-42d6-9102-0014257288e5" />
    <p><a parentName="p" {...{
        "href": "https://github.com/user-attachments/assets/b8635f19-629c-42d6-9102-0014257288e5"
      }}>{`View flowchart in full size`}</a></p>
    <details style={{
      padding: "0.5em 0.5em 0"
    }}>
    <summary>View image description</summary>
      <h3>Success messaging flowchart</h3>
      Is the success of the action evident on the page? If yes, Additional visual messaging not needed, but state must be conveyed to screen reader users. If no, Is the action a results of pressing a save or submit button? If no, display success in a banner or inline messaging on the page in a logical place. Link to newly created item if needed. If yes, Does pressing the save or submit button take you to a different page? If yes, Is success is not evident from the change in URL, display a banner indicating the success. If no, Display inline message in close proximity to the save button.
      <h3>Error messaging flowchart</h3>
      <h4>We know what went wrong branch</h4>
      Do we know what went wrong? If yes, is the error in a form or a text input? If yes, use Primer form validation guidance. If no, Is the error from a failed drag and drop action with a mouse? If yes, Use a simple modal error dialog that can be dismissed with close button or click-outside. Include guidance to correct the error. If no, was the action triggered from choices in a dialog? If no, Banner or inline message closest to the part of the UI that makes logical sense. If yes, Is the error after an action that takes a long time to complete? If no, don't close the dialog until the action completes. If it failed, use a banner at the top of the dialog. If yes, Is it important that the user can move on in the same window while the action completes? If no, don't close the dialog until the action completes. If it failed, use a banner at the top of the dialog. If yes, progress updates in a banner that is replaced with an error or success message. If the user can leave the page during the action, we should utilize GitHub's notification system.
      <h4>We don't know what went wrong branch</h4>
      Do we know what went wrong? If no, is the error obvious without additional messaging? If yes, vague visual error message not needed, but make sure error is conveyed to screen reader users. If no, Is the error in a form or a text input? If yes, use Primer form validation guidance. If no, Is the error a result of something that user initiated? If no, use a banner at the top of the UI if it is crucial to inform the user. If yes, is it crucial that the user knows the error in order to complete their main task? If no, use a banner or inline message closest to the part of the UI that makes logical sense. If yes, use a simple modal error dialog that can be dismissed with close button or click-outside. Include guidance to correct the error.
    </details>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      